<template>
  <v-card
    class="absolute transparent card sizing"
    elevation="0">
    <VideoJsPlayer
      v-if="course.videoUrl"
      :course="course"
      @toggleFavouriteStatus="$emit('toggleFavouriteStatus')"
      @completeCourse="$emit('completeCourse')" />
    <AudioPlayer
      v-else
      :course="course"
      @toggleFavouriteStatus="$emit('toggleFavouriteStatus')"
      @completeCourse="$emit('completeCourse')" />

    <div
      class="course-details-section"
      :class="course.videoUrl ? '' : 'mt-7 py-8'">
      <!-- about -->
      <div class="course-details-section-title">About this course</div>
      <div class="course-about-text mt-3">
        {{ course.description }}
      </div>
      <!-- authors -->
      <div class="course-details-section-title mt-6">created by</div>

      <!-- author -->
      <v-row
        v-if="areAuthorsFetched"
        class="col-12 px-0"
        no-gutters>
        <AuthorThumbnail
          v-for="uid in course.authors"
          :key="uid"
          :uid="uid" />
      </v-row>

      <v-row
        v-else
        class="col-12 px-0"
        no-gutters>
        <v-avatar
          class="mr-4"
          width="44px"></v-avatar>
      </v-row>

      <!-- tags -->
      <div class="course-details-section-title mt-3">tags</div>
      <v-row
        no-gutters
        style="max-width: 45%"
        class="col-12 px-0 py-0">
        <router-link
          v-for="tag in course.tags"
          class="d-flex text-decoration-none mr-4 mt-3 font-weight-medium"
          :to="`/courses-by-tag/${tag}`"
          :key="tag">
          <v-chip color="brandGrey1--text">
            {{ tag }}
          </v-chip>
        </router-link>
      </v-row>
    </div>
  </v-card>
</template>

<script>
  import CourseDetailsComponentMixin from '@/components/courses/course-details/course-details-component/CourseDetailsComponent.mixin.vue';
  export default {
    mixins: [CourseDetailsComponentMixin],
  };
</script>

<style lang="css" scoped>
  .course-details-section {
    background: #ffffff;
    border-radius: 40px;
  }
  .course-details-section-title {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.16em;
    text-transform: uppercase;
    color: var(--v-brandGrey2-base);
  }
  .course-about-text {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: var(--v-brandGrey1-base);
  }
  .course-cover {
    border-radius: 24px;
  }
  .card {
    top: 125px;
    left: 50px;
  }
  .sizing {
    right: 5%;
  }
  @media screen and (min-width: 1340px) {
    .sizing {
      right: 38%;
    }
  }
</style>
